import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  horizontalCardProduct,
  product,
} from "../interface/componentInterface";
import fetchCategoryWiseProduct from "../helper/fetchCategoryWiseProduct";
import { useNavigate } from "react-router-dom";
import useAddToCart from "../helper/addToCart";
import { shuffleArray } from "../common/helperFunction";

const HorizontalCardProduct: React.FC<horizontalCardProduct> = ({
  categoryName,
  headerName,
  useSlider,
  sortOption = "none",
  showAddToCart = true, // Default to false
}) => {
  const [data, setData] = useState<product[]>([]);
  const [loading, setLoading] = useState(true);
  const loadingList = Array(4).fill(null);
  const navigate = useNavigate();
  const addToCart = useAddToCart();

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(categoryName);

    if (categoryProduct.success) {
      const shuffleProduct = shuffleArray(categoryProduct?.data);
      let sortProd = [...shuffleProduct];
      if (sortOption !== "none") {
        sortProd = shuffleProduct.sort((a, b) =>
          sortOption === "lowToHigh"
            ? a.sellingPrice - b.sellingPrice
            : b.sellingPrice - a.sellingPrice
        );
      }

      setData(sortProd);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [categoryName]);

  useEffect(() => {
    setData((prevData: product[]) => {
      let newState = [...prevData];
      let sortProd = [...newState];
      if (sortOption !== "none") {
        sortProd = newState.sort(
          (a, b) =>
            sortOption === "lowToHigh"
              ? a.sellingPrice - b.sellingPrice // Low to High
              : b.sellingPrice - a.sellingPrice // High to Low
        );
      } else {
        sortProd = shuffleArray(newState); // Shuffle if no sort option
      }

      newState = [...sortProd];
      return [...newState];
    });
  }, [sortOption]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.length < 3 ? 2 : 3, // Default number of slides to show
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data.length < 3 ? 2 : 3,
        },
      },
      {
        breakpoint: 815,
        settings: {
          slidesToShow: data.length < 2 ? 1 : 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleProductClick = (productId: string | undefined) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-8">
        {data.length !== 0 && (
          <h2 className="text-2xl font-bold mb-4 capitalize">{headerName}</h2>
        )}
        {useSlider ? (
          <Slider {...sliderSettings}>
            {loading
              ? loadingList.map((_, index) => (
                  <div
                    key={index}
                    className="border border-customGold shadow rounded p-4 flex flex-col"
                  >
                    {/* Placeholder loading state */}
                  </div>
                ))
              : data.map((product) => (
                  <div
                    key={product._id}
                    className="border border-customGold shadow rounded p-4 flex flex-col cursor-pointer"
                    onClick={() => handleProductClick(product._id)}
                  >
                    <img
                      src={product.productImage[0].url}
                      alt={product.productName}
                      className="w-full h-40 object-contain rounded mb-4"
                      style={{ height: "150px" }}
                    />
                    <h3 className="text-lg font-semibold mb-2 truncate">
                      {product.productName}
                    </h3>
                    <p className="text-gray-600 mb-2 truncate">
                      {product.description}
                    </p>
                    <div className="flex flex-col justify-between flex-grow">
                      <div>
                        <p className="text-lg font-semibold text-green-600">
                          ${product.sellingPrice}
                        </p>
                        {product.sellingPrice < product.price && (
                          <p className="text-sm line-through text-gray-500">
                            ${product.price}
                          </p>
                        )}
                      </div>
                      {/* {showAddToCart && (
                        <button
                          className="border border-customGold bg-black text-customGold px-4 py-2 rounded hover:bg-customGoldHover hover:text-black transition-all mt-4 text-center block"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click event from propagating to card
                            addToCart(e, product._id);
                          }}
                        >
                          Add to Cart
                        </button>
                      )} */}
                    </div>
                  </div>
                ))}
          </Slider>
        ) : (
          <div className="grid grid-cols-1 exsm:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {loading
              ? loadingList.map((_, index) => (
                  <div
                    key={index}
                    className="border border-customGold shadow rounded p-4 flex flex-col"
                  >
                    {/* Placeholder loading state */}
                  </div>
                ))
              : data.map((product, _index) => (
                  <div
                    key={`${product._id}_${_index}`}
                    className="border border-customGold shadow rounded p-4 flex flex-col cursor-pointer"
                    onClick={() => handleProductClick(product._id)}
                  >
                    <img
                      src={product.productImage?.[0]?.url}
                      alt={product.productName}
                      className="w-full h-40 object-contain rounded mb-4"
                      style={{ height: "150px" }}
                    />
                    <h3 className="text-lg font-semibold mb-2 truncate">
                      {product.productName}
                    </h3>
                    <p className="text-gray-600 mb-2 truncate">
                      {product.description}
                    </p>
                    <div className="flex flex-col justify-between flex-grow">
                      <div>
                        <p className="text-lg font-semibold text-green-600">
                          Rs.{product.sellingPrice}
                        </p>
                        {product.sellingPrice < product.price && (
                          <p className="text-sm line-through text-gray-500">
                            Rs.{product.price}
                          </p>
                        )}
                      </div>
                      {/* {showAddToCart && (
                        <button
                          className="border border-customGold bg-black text-customGold px-4 py-2 rounded hover:bg-customGoldHover hover:text-black transition-all mt-4 text-center block"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click event from propagating to card
                            addToCart(e, product._id);
                          }}
                        >
                          Add to Cart
                        </button>
                      )} */}
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalCardProduct;
