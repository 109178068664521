const backendDomain = process.env.REACT_APP_BACKEND_URL;

const SummaryAPI = {
  signUp: {
    URL: `${backendDomain}api/signUp`,
    method: "post",
  },
  signIn: {
    URL: `${backendDomain}api/signIn`,
    method: "post",
  },
  currentUser: {
    URL: `${backendDomain}api/userDetails`,
    method: "get",
  },
  logoutUser: {
    URL: `${backendDomain}api/userLogout`,
    method: "get",
  },
  allUser: {
    URL: `${backendDomain}api/allUser`,
    method: "get",
  },
  updateUser: {
    URL: `${backendDomain}api/updateUser`,
    method: "post",
  },
  uploadProduct: {
    URL: `${backendDomain}api/uploadProduct`,
    method: "post",
  },
  getAllProduct: {
    URL: `${backendDomain}api/getAllProduct`,
    method: "get",
  },
  updateProduct: {
    URL: `${backendDomain}api/updateProduct`,
    method: "post",
  },
  getProductCategory: {
    URL: `${backendDomain}api/getProductCategory/`,
    method: "get",
  },
  categoryWiseProduct: {
    URL: `${backendDomain}api/productCategory/`,
    method: "post",
  },
  productDetails: {
    URL: `${backendDomain}api/productDetails/`,
    method: "post",
  },
  addToCartProduct: {
    URL: `${backendDomain}api/addToCart/`,
    method: "post",
  },
  countAddToCartProduct: {
    URL: `${backendDomain}api/countAddToCartProduct/`,
    method: "get",
  },
  cartProducts: {
    URL: `${backendDomain}api/cartProducts/`,
    method: "get",
  },
  updateCartQuantity: {
    URL: `${backendDomain}api/updateCartQuantity/`,
    method: "post",
  },
  removeFromCartController: {
    URL: `${backendDomain}api/removeFromCartController/`,
    method: "post",
  },
  handleDeleteProductImage: {
    URL: `${backendDomain}api/handleDeleteProductImage/`,
    method: "post",
  },
  deleteProduct: {
    URL: `${backendDomain}api/deleteProduct/`,
    method: "post",
  },
  sendmail: {
    URL: `${backendDomain}api/sendmail/`,
    method: "get",
  },
  placeOrder: {
    URL: `${backendDomain}api/placeOrder/`,
    method: "post",
  },
  allOrderDetails: {
    URL: `${backendDomain}api/allOrder/`,
    method: "get",
  },
  updatePaymentStatus: {
    URL: `${backendDomain}api/updatePaymentStatus/`,
    method: "post",
  },
  updateDeliveryStatus: {
    URL: `${backendDomain}api/updateDeliveryStatus/`,
    method: "post",
  },
  getOrderDetails: {
    URL: `${backendDomain}api/getOrderDetails/`,
    method: "get",
  },
  uploadSliderImage: {
    URL: `${backendDomain}api/uploadSliderImage/`,
    method: "post",
  },
  fetchSliderImage: {
    URL: `${backendDomain}api/fetchSliderImage/`,
    method: "get",
  },
};

export default SummaryAPI;
