import React, { useContext, useState } from "react";
import { MdModeEditOutline, MdDelete } from "react-icons/md"; // Import the delete icon
import AdminEditProduct from "./AdminEditProduct";
import displayINRCurrency from "../helper/displayINRCurrency";
import { adminProductCardProps } from "../interface/componentInterface";
import { customToast } from "../common/helperFunction";
import SummaryAPI from "../common";
import Context from "../context";

const AdminProductCard: React.FC<adminProductCardProps> = ({
  data,
  fetchdata,
}) => {
  const [editProduct, setEditProduct] = useState(false);
  const context = useContext(Context);
  // Function to handle product deletion
  const handleDeleteProduct = async () => {
    const response = await fetch(SummaryAPI.deleteProduct.URL, {
      method: SummaryAPI.deleteProduct.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ productId: data._id }),
    });

    const responseData = await response.json();
    if (responseData.success) {
      customToast(responseData?.message, "success");
      fetchdata();
      context?.fetchUserAddToCart();
    } else if (responseData.error) {
      customToast(responseData?.message, "error");
    }
  };

  return (
    <div className="border border-customGold p-4 rounded">
      <div className="w-40">
        <div className="w-32 h-32 flex justify-center items-center text-center mx-auto">
          <img
            src={data?.productImage?.[0]?.url}
            alt={"img"}
            className="mx-auto object-fill w-full h-full items-center"
          />
        </div>
        <h1 className="pt-3 text-ellipsis line-clamp-2">{data.productName}</h1>

        <div>
          <p className="font-semibold">
            {displayINRCurrency(Number(data.sellingPrice))}
          </p>

          <div className="flex justify-between items-center">
            <div
              className="w-fit p-2 bg-slate-700 rounded-full cursor-pointer hover:bg-slate-900"
              onClick={() => setEditProduct(true)}
            >
              <MdModeEditOutline />
            </div>

            <div
              className="w-fit p-2 bg-red-600 rounded-full cursor-pointer hover:bg-red-800"
              onClick={handleDeleteProduct} // Call delete function on click
            >
              <MdDelete />
            </div>
          </div>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct
          productData={data}
          key={"productKey"}
          onClose={() => setEditProduct(false)}
          fetchdata={fetchdata}
        />
      )}
    </div>
  );
};

export default AdminProductCard;
