import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgotPassword";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Products from "../pages/Products";
import Cart from "../pages/Cart";
import WishList from "../pages/WishList";
import SignUp from "../pages/SignUp";
import AdminPanel from "../pages/AdminPanel";
import AllUser from "../pages/AllUser";
import AllProduct from "../pages/AllProduct";
import CategoryProducts from "../pages/CategoryProducts";
import ProductDetails from "../pages/ProductDetails";
import CheckoutPage from "../pages/CheckoutPage";
import AllOrders from "../pages/AllOrder";
import OrderList from "../pages/OrderList";
import OrderPage from "../pages/OrderPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "aboutUs",
        element: <AboutUs />,
      },
      {
        path: "contactUs",
        element: <ContactUs />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "myOrders",
        element: <OrderList />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "wishList",
        element: <WishList />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgotPassword",
        element: <ForgetPassword />,
      },
      {
        path: "signUp",
        element: <SignUp />,
      },
      {
        path: "productCategory/:categoryName",
        element: <CategoryProducts />,
      },
      {
        path: "product/:id",
        element: <ProductDetails />,
      },
      {
        path: "order/:id",
        element: <OrderPage />,
      },
      {
        path: "checkout/:id",
        element: <CheckoutPage />,
      },
      {
        path: "adminPanel",
        element: <AdminPanel />,
        children: [
          {
            path: "allUser",
            element: <AllUser />,
          },
          {
            path: "allProduct",
            element: <AllProduct />,
          },
          {
            path: "allOrder",
            element: <AllOrders />,
          },
        ],
      },
    ],
  },
]);

export default router;
