import { toast } from "react-toastify";
import { product } from "../interface/componentInterface";

export const customToast = (msg: string, type: "error" | "success") => {
  if (type === "success") {
    // console.log(msg, "toast popup");
    toast.success(msg, {
      bodyClassName: "text-customGold",
    });
  } else {
    // console.log(msg, "toast popup");
    toast.error(msg, {
      bodyClassName: "text-customGold",
    });
  }
};

export const productCategory = [
  { id: 1, label: "T-Shirt", value: "tShirt" },
  { id: 2, label: "Hoodies", value: "hoodies" },
  { id: 3, label: "Cod Set", value: "codSet" },
  // { id: 4, label: "Frocks", value: "frocks" },
];

export const shuffleArray = (arr: product[]) => {
  const array = [...arr]; // Make a copy of the array to avoid mutating the original
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};
