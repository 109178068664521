import React, { useState, useEffect } from "react";
import { product } from "../interface/componentInterface";
import { useNavigate, useParams } from "react-router-dom";
import SummaryAPI from "../common";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import displayINRCurrency from "../helper/displayINRCurrency";
import HorizontalCardProduct from "../component/HorizontalCardProduct";
import useAddToCart from "../helper/addToCart";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { customToast } from "../common/helperFunction";

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState<
    product & { size: string[] }
  >({
    _id: undefined,
    productName: "",
    category: "",
    productImage: [{ url: "", publicId: "" }],
    description: "",
    price: 0,
    sellingPrice: 0,
    size: [], // Add size to the product details state
  });
  const addToCart = useAddToCart();
  const [selectedSize, setSelectedSize] = useState<string>(""); // Manage selected size
  const [activeImage, setActiveImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const productImageListLoading = new Array(4).fill(null);
  const userDetails = useSelector((state: RootState) => state?.user?.user);
  const [isProcessing, setIsProcessing] = useState(false);

  const params = useParams();

  const fetchProductDetail = async () => {
    setLoading(true);
    const response = await fetch(SummaryAPI.productDetails.URL, {
      method: SummaryAPI.productDetails.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ productId: params.id }),
    });

    const dataResponse = await response.json();
    if (dataResponse.success) {
      setProductDetails(dataResponse.data);
      setActiveImage(dataResponse.data.productImage[0]?.url || "");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchProductDetail();
  }, [params.id]);

  const handleMouseEnterProduct = (imageURL: string) => {
    setActiveImage(imageURL);
  };

  const handleAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    if (selectedSize) {
      await addToCart(e, id, quantity, selectedSize); // Passing quantity and selected size
    } else {
      customToast("Please select a size before adding to cart", "error");
    }
  };

  const handleBuyProduct = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    if (!isProcessing) {
      setIsProcessing(true);

      if (userDetails) {
        // await handleAddToCart(e,id);

        const itemDetails = {
          id: id,
          name: productDetails.productName,
          imageUrl: productDetails.productImage[0].url,
          price: productDetails.sellingPrice,
          quantity: quantity,
          size: selectedSize, // Added size
        };
        if (selectedSize) {
          navigate(`/checkout/${itemDetails.id}`, {
            state: { checkoutItems: [itemDetails] },
          });
        } else {
          customToast("Please select a size before proceeding", "error");
        }
      } else {
        customToast("Please login before proceeding for checkout", "error");
        navigate(`/login`);
      }
      setIsProcessing(false);
    }
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  // Calculate Discount Percentage
  const discountPercentage = Math.round(
    ((productDetails.price - productDetails.sellingPrice) /
      productDetails.price) *
      100
  );

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Product Image */}
          <div className="flex flex-col lg:flex-row-reverse gap-4 lg:w-1/2">
            {/* Product Image */}
            <div className="relative w-full max-w-[300px] max-h-[300px] overflow-hidden">
              <Zoom>
                <img
                  src={activeImage}
                  className="w-full h-auto max-w-[300px] max-h-[300px] object-contain" // Responsive sizing
                  alt="Product"
                />
              </Zoom>
            </div>

            <div className="flex overflow-x-auto lg:flex-col lg:overflow-visible gap-2 lg:gap-4">
              {loading
                ? productImageListLoading.map((_, index) => (
                    <div
                      className="h-20 w-20 rounded animate-pulse"
                      key={"loadingImage" + index}
                    />
                  ))
                : productDetails?.productImage?.map((imgURL) => (
                    <div
                      className="h-20 w-20 rounded overflow-hidden cursor-pointer"
                      key={imgURL.publicId}
                      onMouseEnter={() => handleMouseEnterProduct(imgURL.url)}
                      onClick={() => handleMouseEnterProduct(imgURL.url)}
                    >
                      <img
                        src={imgURL.url}
                        className="w-full h-full object-contain"
                        alt="Product Thumbnail"
                      />
                    </div>
                  ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="flex flex-col gap-4 lg:w-1/2">
            {loading ? (
              <div className="space-y-4">
                <div className="h-8 bg-slate-200 animate-pulse rounded w-full"></div>
                <div className="h-6 bg-slate-200 animate-pulse rounded w-full"></div>
                <div className="h-6 bg-slate-200 animate-pulse rounded w-1/2"></div>
                <div className="h-6 bg-slate-200 animate-pulse rounded w-1/4"></div>
                <div className="space-x-4">
                  <div className="h-8 bg-slate-200 animate-pulse rounded inline-block w-1/3"></div>
                  <div className="h-8 bg-slate-200 animate-pulse rounded inline-block w-1/3"></div>
                </div>
                <div className="h-8 bg-slate-200 animate-pulse rounded w-full"></div>
                <div className="h-16 bg-slate-200 animate-pulse rounded w-full"></div>
              </div>
            ) : (
              <div className="space-y-4">
                <h2 className="text-2xl lg:text-3xl font-bold">
                  {productDetails?.productName}
                </h2>
                <p className="capitalize">{productDetails?.category}</p>
                <div className="flex flex-wrap items-center gap-4 text-xl lg:text-2xl font-semibold">
                  <p className="">
                    {displayINRCurrency(productDetails.sellingPrice)}
                  </p>
                  <p className="text-gray-500 line-through">
                    {displayINRCurrency(productDetails.price)}
                  </p>
                  {discountPercentage > 0 && (
                    <span className="text-lg lg:text-xl text-green-600 font-medium">
                      {discountPercentage}% OFF
                    </span>
                  )}
                </div>

                {/* Size Selector */}
                {productDetails.size.length > 0 && (
                  <div className="mt-4">
                    <label
                      htmlFor="sizeSelect"
                      className="block text-lg font-medium"
                    >
                      Size:
                    </label>
                    <select
                      id="sizeSelect"
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                      className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                    >
                      <option value="">Select Size</option>
                      {productDetails.size.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Quantity Selector */}
                <div className="flex items-center gap-2 mt-4">
                  <button
                    className="px-3 py-1 border border-customGold rounded"
                    onClick={decreaseQuantity}
                    disabled={quantity <= 1}
                  >
                    -
                  </button>
                  <span className="text-lg">{quantity}</span>
                  <button
                    className="px-3 py-1 border border-customGold rounded"
                    onClick={increaseQuantity}
                  >
                    +
                  </button>
                </div>

                <div className="flex flex-wrap gap-4 mt-4">
                  <button
                    className="flex-1 min-w-[120px] border-2 border-customGold rounded px-4 py-2 font-medium hover:text-black hover:bg-customGold transition"
                    onClick={(e) =>
                      handleBuyProduct(e, productDetails?._id as string)
                    }
                    disabled={isProcessing}
                  >
                    Buy
                  </button>
                  <button
                    className="flex-1 min-w-[120px] border-2 border-customGold rounded px-4 py-2 text-black bg-customGold font-medium hover:bg-black hover:text-customGoldHover transition"
                    onClick={(e) =>
                      handleAddToCart(e, productDetails?._id as string)
                    }
                  >
                    Add To Cart
                  </button>
                </div>
                <div className="mt-4">
                  <p className="font-medium">Description:</p>
                  <p>{productDetails?.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <HorizontalCardProduct
        headerName={""}
        categoryName={"all"}
        useSlider={true}
        showAddToCart={false}
      />
    </>
  );
};

export default ProductDetails;
