import React, { useState, useEffect } from "react";
import SummaryAPI from "../common";
import { IPlaceOrder } from "../interface/pageInterface";
import moment from "moment";
import { customToast } from "../common/helperFunction";

const AllOrders = () => {
  const [allOrders, setAllOrders] = useState<IPlaceOrder[]>([]);

  // Fetch Orders
  const fetchAllOrders = async () => {
    const fetchOrdersData = await fetch(SummaryAPI.allOrderDetails.URL, {
      method: SummaryAPI.allOrderDetails.method,
      credentials: "include",
    });
    const orderResponse = await fetchOrdersData.json();
    if (orderResponse.success) {
      setAllOrders(orderResponse.data);
      console.log(orderResponse.data, "here checking");
    }
  };

  // Update Payment Status
  const updatePaymentStatus = async (orderId: string, newStatus: string) => {
    // Call your API to update payment status here
    // For example:
    const fetchPaymentData = await fetch(SummaryAPI.updatePaymentStatus.URL, {
      method: SummaryAPI.updatePaymentStatus.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId, paymentStatus: newStatus }),
      credentials: "include",
    });

    const paymentUpdated = await fetchPaymentData.json();
    if (paymentUpdated.success) {
      customToast(paymentUpdated.message, "success");
    }
    if (paymentUpdated.error) {
      customToast(paymentUpdated.message, "error");
    }

    // Fetch orders again to get updated data
    fetchAllOrders();
  };

  // Update Delivery Status
  const updateDeliveryStatus = async (orderId: string, newStatus: string) => {
    const fetchPaymentData = await fetch(SummaryAPI.updateDeliveryStatus.URL, {
      method: SummaryAPI.updateDeliveryStatus.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId: orderId, deliveryStatus: newStatus }),
      credentials: "include",
    });

    const paymentUpdated = await fetchPaymentData.json();
    if (paymentUpdated.success) {
      customToast(paymentUpdated.message, "success");
    }
    if (paymentUpdated.error) {
      customToast(paymentUpdated.message, "error");
    }

    // Fetch orders again to get updated data
    fetchAllOrders();
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const thClassName =
    "px-3 py-3 border-b-2 border-gray-500 bg-gray-900 text-left text-xs sm:text-sm font-semibold whitespace-nowrap text-center";
  const tdClassName =
    "px-3 py-2 border-b border-gray-500 text-xs sm:text-sm truncate text-center";

  return (
    <div className="overflow-x-auto scrollbar-none w-full max-w-screen-xl mx-auto">
      <h2 className="text-xl font-semibold mb-4">All Orders</h2>
      <table className="w-full orderTable">
        <thead>
          <tr>
            <th className={thClassName}>Order ID</th>
            <th className={thClassName}>User ID</th>
            {/* <th className={thClassName}>Product Name</th> */}
            <th className={thClassName}>Email ID</th>
            <th className={thClassName}>Customer Name</th>
            <th className={thClassName}>Customer Mobile Number</th>
            <th className={thClassName}>Customer Address</th>
            <th className={thClassName}>Payment Mode</th>
            <th className={thClassName}>Payment Status</th>
            <th className={thClassName}>Delivery Status</th>
            <th className={thClassName}>Order Items</th>
            <th className={thClassName}>Order date</th>
          </tr>
        </thead>
        <tbody>
          {allOrders.map((_order, _index) => (
            <tr key={_index}>
              <td className={tdClassName}>{_order._id}</td>
              <td className={tdClassName}>{_order.userId}</td>
              {/* <td className={tdClassName}>
                {_order.items.map((item, _itemKey) => (
                  <div key={_index + item.id + _itemKey} className="my-1">
                    {item.name}
                  </div>
                ))}
              </td> */}
              <td className={tdClassName}>{_order.customerEmailId}</td>
              <td className={tdClassName}>{_order.customerName}</td>
              <td className={tdClassName}>{_order.customerMobile}</td>
              <td className={tdClassName}>{_order.customerAddress}</td>
              <td className={tdClassName}>{_order.paymentMode}</td>
              <td className={tdClassName}>
                <select
                  value={_order.paymentStatus}
                  onChange={(e) =>
                    updatePaymentStatus(_order._id, e.target.value)
                  }
                  className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Failed">Failed</option>
                </select>
              </td>
              <td className={tdClassName}>
                <select
                  value={_order.deliveryStatus}
                  onChange={(e) =>
                    updateDeliveryStatus(_order._id, e.target.value)
                  }
                  className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                >
                  <option value="Processing">Processing</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </td>
              <td className={tdClassName}>
                {_order.items.map((item, _itemKey) => (
                  <div key={item.id + _itemKey} className="my-1">
                    {item.name} - {item.quantity} x {item.size}
                  </div>
                ))}
              </td>
              <td className={tdClassName}>
                {new Date(_order.createdAt).toLocaleDateString("en-IN", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}{" "}
                {new Date(_order.createdAt).toLocaleTimeString("en-IN", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllOrders;
