import { productCategory } from "../common/helperFunction";
import BannerProduct from "../component/BannerProduct";
import CategoryList from "../component/CategoryList";
import HorizontalCardProduct from "../component/HorizontalCardProduct";

const Home = () => {
  return (
    <div>
      <CategoryList />
      <BannerProduct />
      {productCategory.map((_prodDetails, _index) => {
        return (
          <HorizontalCardProduct
            key={`${_prodDetails.value}_${_index}`}
            headerName={_prodDetails.label}
            categoryName={_prodDetails.value}
            useSlider={false}
          />
        );
      })}

      {/* <HorizontalCardProduct
        headerName={productCategory[1].label}
        categoryName={productCategory[1].value}
        useSlider={false}
      />
      <HorizontalCardProduct
        headerName={productCategory[2].label}
        categoryName={productCategory[2].value}
        useSlider={false}
      />
      <HorizontalCardProduct
        headerName={productCategory[3].label}
        categoryName={productCategory[3].value}
        useSlider={false}
      /> */}
    </div>
  );
};

export default Home;
