import React, { useState, ChangeEvent } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DOMPurify from "dompurify";
import SummaryAPI from "../../src/common/index";
import { useNavigate } from "react-router-dom";
import { customToast } from "../common/helperFunction";

// Utility function to sanitize and validate inputs
const sanitizeInput = (input: string) => DOMPurify.sanitize(input);

const validateSignup = (
  firstName: string,
  lastName: string,
  email: string,
  mobile: string,
  password: string
) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/; // Adjust this regex according to your mobile number format requirements
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  if (!firstName || !lastName || !email || !mobile || !password)
    return "All fields are required.";
  if (!emailRegex.test(email)) return "Invalid email format.";
  if (!phoneRegex.test(mobile))
    return "Invalid mobile number format. Must be 10 digits.";
  if (!passwordRegex.test(password))
    return "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character.";
  return null;
};

const useInputHandler = (initialValue: string) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(sanitizeInput(e.target.value));
  };

  return [value, handleChange] as const;
};

const SignUp: React.FC = () => {
  const [firstName, handleFirstNameChange] = useInputHandler("");
  const [lastName, handleLastNameChange] = useInputHandler("");
  const [email, handleEmailChange] = useInputHandler("");
  const [mobile, handleMobileChange] = useInputHandler("");
  const [password, handlePasswordChange] = useInputHandler("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validationError = validateSignup(
      firstName,
      lastName,
      email,
      mobile,
      password
    );
    if (validationError) {
      // setError(validationError);
      // console.log(validationError);
      customToast(validationError, "error");
      return;
    }

    try {
      const response = await fetch(SummaryAPI.signUp.URL, {
        method: SummaryAPI.signUp.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          emailId: email,
          password,
          mobileNumber: mobile,
        }),
      });

      const data = await response.json();

      if (data.success) {
        customToast(data.message, "success");
        navigate("/login");
      } else {
        // setError(data.message || "An error occurred. Please try again.");
        customToast(data.message, "error");
      }
    } catch (error) {
      // setError("An unexpected error occurred. Please try again later.");
      customToast(
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Sign Up</h2>
        <form className="space-y-4" onSubmit={handleSignup}>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-1/2">
              <label htmlFor="firstName" className="block text-sm font-medium">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                value={firstName}
                onChange={handleFirstNameChange}
                placeholder="Enter your first name"
              />
            </div>
            <div className="w-full md:w-1/2 mt-4 md:mt-0">
              <label htmlFor="lastName" className="block text-sm font-medium">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
                value={lastName}
                onChange={handleLastNameChange}
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label htmlFor="mobile" className="block text-sm font-medium">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={mobile}
              onChange={handleMobileChange}
              placeholder="Enter your mobile number"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              type={passwordVisible ? "text" : "password"}
              id="password"
              className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-10 right-0 pr-3 flex items-center text-customGold"
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-black text-customGold rounded-md border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
