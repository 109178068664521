import React, { useEffect, useState } from "react";
import {
  sliderProperties,
  uploadSliderProps,
} from "../interface/componentInterface";
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { customToast } from "../common/helperFunction";
import uploadImage from "../helper/uploadImage";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../style/customZoom.css"; // Custom styles
import SummaryAPI from "../common";

const UploadSliderImage: React.FC<uploadSliderProps> = ({ onClose }) => {
  const [data, setData] = useState<sliderProperties>({
    sliderImage: [],
  });

  const handleUploadProduct = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const uploadImageCloudinary = await uploadImage(file);
      setData((prev) => ({
        ...prev,
        sliderImage: [
          ...prev.sliderImage,
          {
            url: uploadImageCloudinary.secure_url,
            publicId: uploadImageCloudinary.public_id,
          },
        ],
      }));
    }
  };

  const handleDeleteProductImageFn = async (index: number) => {
    const imageToDelete = data.sliderImage[index];
    if (!imageToDelete || !imageToDelete.publicId) {
      customToast("No image found to delete.", "error");
      return;
    }

    setData((prev) => ({
      ...prev,
      sliderImage: prev.sliderImage.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(SummaryAPI.uploadSliderImage.URL, {
        method: SummaryAPI.uploadSliderImage.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        customToast(responseData.message, "success");
        onClose();
      } else if (responseData.error) {
        customToast(responseData.message, "error");
      }
    } catch (error) {
      console.error("Error uploading carousel:", error);
      customToast("An error occurred while uploading the carousel.", "error");
    }
  };

  const fetchImage = async () => {
    try {
      const response = await fetch(SummaryAPI.fetchSliderImage.URL, {
        method: SummaryAPI.fetchSliderImage.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      });
      const responseData = await response.json();
      if (responseData.success) {
        // console.log(responseData.data);
        setData(responseData.data[0]);
      } else if (responseData.error) {
        customToast(responseData.message, "error");
      }
    } catch (error) {
      console.error("Error fetching carousel:", error);
      customToast("An error occurred while fetching the carousel.", "error");
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div className="fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 flex justify-center items-center z-30">
      <div className="p-4 bg-black border border-customGold rounded-md w-full max-w-lg max-h-[80%] overflow-y-auto scrollbar-none">
        <div className="flex justify-between items-center pb-3">
          <h2 className="font-bold text-lg text-customGold">
            Upload Carousel Image
          </h2>
          <button
            className="text-2xl text-customGold hover:text-customGoldHover"
            onClick={onClose}
          >
            <CgClose />
          </button>
        </div>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <label htmlFor="uploadImageInput" className="cursor-pointer">
            <div className="p-4 border-2 border-dashed border-customGold rounded-lg flex flex-col items-center justify-center h-32  transition-all">
              <FaCloudUploadAlt className="text-4xl text-customGold" />
              <span className="text-sm text-customGold mt-2">
                Click or drag image here to upload
              </span>
              <input
                type="file"
                id="uploadImageInput"
                className="hidden"
                accept="image/*"
                onChange={handleUploadProduct}
              />
            </div>
          </label>

          <div className="mt-3">
            {data.sliderImage.length > 0 ? (
              <div className="flex flex-wrap gap-3">
                {data.sliderImage.map((image, index) => (
                  <div
                    key={index}
                    className="relative border border-customGold rounded-lg overflow-hidden"
                  >
                    <Zoom>
                      <img
                        src={image.url}
                        alt={`Product Image ${index}`}
                        className="w-full h-full object-cover" // Optional: can adjust sizing here
                      />
                    </Zoom>
                    <button
                      type="button"
                      onClick={() => handleDeleteProductImageFn(index)}
                      className="absolute top-1 right-1 text-xl text-red-500 hover:text-red-700"
                    >
                      <MdDelete />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-red-500 text-sm">
                *Please upload carousel image
              </p>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-2 border border-customGold text-customGold hover:bg-customGoldHover hover:text-black rounded-md transition-all"
            >
              Upload Carousel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadSliderImage;
