import React, { useState } from "react";
import { product, uploadProductProps } from "../interface/componentInterface";
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { customToast, productCategory } from "../common/helperFunction";
import uploadImage from "../helper/uploadImage";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../style/customZoom.css"; // Custom styles
import SummaryAPI from "../common";

const UploadProduct: React.FC<uploadProductProps> = (props) => {
  const { onClose, fetchData } = props;

  const [data, setData] = useState<product>({
    productName: "",
    category: "",
    productImage: [],
    description: "",
    price: 0,
    sellingPrice: 0,
    size: [],
  });

  const [newSize, setNewSize] = useState("");

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUploadProduct = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const uploadImageCloudinary = await uploadImage(file);
      setData((prev) => ({
        ...prev,
        productImage: [
          ...prev.productImage,
          {
            url: uploadImageCloudinary.secure_url,
            publicId: uploadImageCloudinary.public_id,
          },
        ],
      }));
    }
  };

  const handleDeleteProductImageFn = (index: number) => {
    setData((prev) => ({
      ...prev,
      productImage: prev.productImage.filter((_, i) => i !== index),
    }));
  };

  const handleAddSize = () => {
    if (newSize.trim()) {
      setData((prev) => ({
        ...prev,
        size: [...prev.size, newSize.trim()],
      }));
      setNewSize("");
    }
  };

  const handleDeleteSize = (index: number) => {
    const newSizeArray = [...data.size];
    newSizeArray.splice(index, 1);
    setData((prev) => ({
      ...prev,
      size: newSizeArray,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation checks
    if (!data.productName.trim()) {
      customToast("Please enter a product name.", "error");
      return;
    }

    if (!data.category) {
      customToast("Please select a category.", "error");
      return;
    }

    if (!data.description.trim()) {
      customToast("Please enter a product description.", "error");
      return;
    }

    if (data.productImage.length === 0) {
      customToast("Please upload at least one product image.", "error");
      return;
    }

    if (data.size.length === 0) {
      customToast("Please add at least one size.", "error");
      return;
    }

    if (data.price <= 0) {
      customToast("Price must be greater than 0.", "error");
      return;
    }

    if (data.sellingPrice <= 0) {
      customToast("Selling price must be greater than 0.", "error");
      return;
    }

    if (data.sellingPrice > data.price) {
      customToast(
        "Selling price cannot be greater than the original price.",
        "error"
      );
      return;
    }

    try {
      const response = await fetch(SummaryAPI.uploadProduct.URL, {
        method: SummaryAPI.uploadProduct.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        customToast(responseData.message, "success");
        onClose();
        fetchData();
      } else if (responseData.error) {
        customToast(responseData.message, "error");
      }
    } catch (error) {
      console.error("Error uploading product:", error);
      customToast("An error occurred while uploading the product.", "error");
    }
  };

  return (
    <div className="fixed w-full h-full bg-black bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30">
      <div className="p-2 pb-16 bg-black border border-customGold rounded w-full max-w-[90%] h-full max-h-[80%] overflow-hidden">
        <div className="flex justify-between items-center pb-3">
          <h2 className="font-bold text-lg">Upload Product</h2>
          <div
            className="w-fit ml-auto text-2xl hover:text-customGoldHover cursor-pointer"
            onClick={onClose}
          >
            <CgClose />
          </div>
        </div>

        <form
          className="grid pt-4 pr-4 pl-4 gap-2 overflow-auto h-full scrollbar-none"
          onSubmit={handleSubmit}
        >
          <label htmlFor="productName">Product Name :</label>
          <input
            type="text"
            id="productName"
            placeholder="Enter product name"
            name="productName"
            value={data.productName}
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border h-12 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <label htmlFor="category" className="mt-3">
            Category :
          </label>
          <select
            id="category"
            required
            value={data.category}
            name="category"
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border h-12 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
          >
            <option value={""}>Select Category</option>
            {productCategory.map((_category, _index) => {
              return (
                <option value={_category.value} key={_category.value + _index}>
                  {_category.label}
                </option>
              );
            })}
          </select>

          <label htmlFor="sizeInput" className="mt-3">
            Size :
          </label>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <input
              type="text"
              id="sizeInput"
              placeholder="Enter product size"
              value={newSize}
              onChange={(e) => setNewSize(e.target.value)}
              className="bg-black mt-1 block w-full md:w-3/4 p-3 border h-12 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            />
            <button
              type="button"
              onClick={handleAddSize}
              className="px-3 py-2 mt-2 md:mt-0 border border-customGold hover:bg-customGoldHover hover:text-black rounded-md"
            >
              Add Size
            </button>
          </div>

          <div className="mt-3">
            {data.size.length > 0 ? (
              <ul className="list-disc pl-5">
                {data.size.map((size, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <span>{size}</span>
                    <button
                      type="button"
                      onClick={() => handleDeleteSize(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <MdDelete />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-red-600 text-xs">*Please add product sizes</p>
            )}
          </div>

          <label htmlFor="uploadImageInput" className="mt-3">
            Product Image :
          </label>
          <label htmlFor="uploadImageInput">
            <div className="p-2 border-customGold border rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="flex justify-center items-center flex-col gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <span className="text-xs text-center">
                  Click or drag image here to upload
                </span>
              </div>
              <input
                type="file"
                id="uploadImageInput"
                className="hidden"
                accept="image/*"
                onChange={handleUploadProduct}
              />
            </div>
          </label>

          <div className="mt-3">
            {data.productImage.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {data.productImage.map((image, index) => (
                  <div
                    key={index}
                    className="relative w-32 h-32 border border-customGold rounded overflow-hidden"
                  >
                    <Zoom>
                      <img
                        src={image.url}
                        alt={`Product Image ${index}`}
                        className="w-full h-full object-cover"
                      />
                    </Zoom>
                    <button
                      type="button"
                      onClick={() => handleDeleteProductImageFn(index)}
                      className="absolute top-1 right-1 text-red-600 hover:text-red-800 text-2xl"
                    >
                      <MdDelete />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-red-600 text-xs">
                *Please upload at least one image
              </p>
            )}
          </div>

          <label htmlFor="description" className="mt-3">
            Description :
          </label>
          <textarea
            id="description"
            rows={4}
            placeholder="Enter product description"
            name="description"
            value={data.description}
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border h-32 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold resize-none"
            required
          />

          <label htmlFor="price" className="mt-3">
            Price :
          </label>
          <input
            type="number"
            id="price"
            placeholder="Enter product price"
            name="price"
            value={data.price}
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border h-12 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <label htmlFor="sellingPrice" className="mt-3">
            Selling Price :
          </label>
          <input
            type="number"
            id="sellingPrice"
            placeholder="Enter product selling price"
            name="sellingPrice"
            value={data.sellingPrice}
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border h-12 placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <button
            type="submit"
            className="mt-4 bg-customGold w-full py-2 rounded text-black font-semibold hover:bg-customGoldHover"
          >
            Upload Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default UploadProduct;
