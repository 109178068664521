import React, { useState, useEffect } from "react";
import {
  adminEditProductProps,
  product,
} from "../interface/componentInterface";
import uploadImage from "../helper/uploadImage";
import SummaryAPI from "../common";
import { customToast, productCategory } from "../common/helperFunction";
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css"; // Ensure you include this for Zoom component
import DisplayImage from "./DisplayImage";

const AdminEditProduct: React.FC<adminEditProductProps> = ({
  productData,
  onClose,
  fetchdata,
}) => {
  const [data, setData] = useState<product & { size: string[] }>({
    ...productData,
    productName: productData?.productName,
    category: productData?.category,
    productImage: productData?.productImage || [],
    description: productData?.description,
    price: productData?.price,
    sellingPrice: productData?.sellingPrice,
    size: productData?.size || [], // Include size in the state
  });

  const [newSize, setNewSize] = useState<string>("");
  const [openFullScreenImage, setOpenFullScreenImage] =
    useState<boolean>(false);
  const [fullScreenImage, setFullScreenImage] = useState<string>("");

  useEffect(() => {
    // Fetch the product sizes from the database if needed
    // Here we assume the size data is included in the productData passed to the component
  }, [productData]);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUploadProduct = async (e: any) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);
    setData((prev) => ({
      ...prev,
      productImage: [
        ...prev.productImage,
        {
          url: uploadImageCloudinary.url,
          publicId: uploadImageCloudinary.public_id,
        },
      ],
    }));
  };

  const handleDeleteProductImage = async (index: number) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);
    setData((prev) => ({
      ...prev,
      productImage: newProductImage,
    }));
  };

  const handleAddSize = () => {
    if (newSize.trim()) {
      setData((prev) => ({
        ...prev,
        size: [...prev.size, newSize.trim()],
      }));
      setNewSize("");
    }
  };

  const handleDeleteSize = (index: number) => {
    const newSizeArray = [...data.size];
    newSizeArray.splice(index, 1);
    setData((prev) => ({
      ...prev,
      size: newSizeArray,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Validation checks
    if (!data.productName.trim()) {
      customToast("Please enter a product name.", "error");
      return;
    }

    if (!data.category) {
      customToast("Please select a category.", "error");
      return;
    }

    if (!data.description.trim()) {
      customToast("Please enter a product description.", "error");
      return;
    }

    if (data.productImage.length === 0) {
      customToast("Please upload at least one product image.", "error");
      return;
    }

    if (data.size.length === 0) {
      customToast("Please add at least one size.", "error");
      return;
    }

    if (data.price <= 0) {
      customToast("Price must be greater than 0.", "error");
      return;
    }

    if (data.sellingPrice <= 0) {
      customToast("Selling price must be greater than 0.", "error");
      return;
    }

    if (data.sellingPrice > data.price) {
      customToast(
        "Selling price cannot be greater than the original price.",
        "error"
      );
      return;
    }

    const response = await fetch(SummaryAPI.updateProduct.URL, {
      method: SummaryAPI.updateProduct.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.success) {
      customToast(responseData?.message, "success");
      onClose();
      fetchdata();
    } else if (responseData.error) {
      customToast(responseData?.message, "error");
    }
  };

  return (
    <div className="fixed w-full h-full bg-black bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30">
      <div className="p-2 pb-16 bg-black border border-customGold rounded w-full max-w-[90%] h-full max-h-[80%] overflow-hidden">
        <div className="flex justify-between items-center pb-3">
          <h2 className="font-bold text-lg">Edit Product</h2>
          <div
            className="w-fit ml-auto text-2xl hover:text-customGoldHover cursor-pointer"
            onClick={onClose}
          >
            <CgClose />
          </div>
        </div>

        <form
          className="grid pt-4 pr-4 pl-4 gap-2 overflow-auto h-full scrollbar-none"
          onSubmit={handleSubmit}
        >
          <label htmlFor="productName">Product Name :</label>
          <input
            type="text"
            id="productName"
            placeholder="Enter product name"
            name="productName"
            value={data.productName}
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <label htmlFor="category" className="mt-3">
            Category :
          </label>
          <select
            id="category"
            required
            value={data.category}
            name="category"
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
          >
            <option value={""}>Select Category</option>
            {productCategory.map((el, index) => (
              <option value={el.value} key={el.value + index}>
                {el.label}
              </option>
            ))}
          </select>

          <label htmlFor="sizeInput" className="mt-3">
            Size :
          </label>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <input
              type="text"
              id="sizeInput"
              placeholder="Enter product size"
              value={newSize}
              onChange={(e) => setNewSize(e.target.value)}
              className="bg-black mt-1 block w-full md:w-3/4 p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            />
            <button
              type="button"
              onClick={handleAddSize}
              className="px-3 py-2 mt-2 md:mt-0 border border-customGold hover:bg-customGoldHover hover:text-black rounded-md"
            >
              Add Size
            </button>
          </div>

          <div className="mt-3">
            {data.size.length > 0 ? (
              <ul className="list-disc pl-5">
                {data.size.map((size, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <span>{size}</span>
                    <button
                      type="button"
                      onClick={() => handleDeleteSize(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <MdDelete />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-red-600 text-xs">*Please add product sizes</p>
            )}
          </div>

          <label htmlFor="uploadImageInput" className="mt-3">
            Product Image :
          </label>
          <label htmlFor="uploadImageInput">
            <div className="p-2 border border-customGold rounded h-32 w-full flex justify-center items-center cursor-pointer">
              <div className="flex justify-center items-center flex-col gap-2">
                <span className="text-4xl">
                  <FaCloudUploadAlt />
                </span>
                <p className="text-sm">Upload Product Image</p>
                <input
                  type="file"
                  id="uploadImageInput"
                  className="hidden"
                  onChange={handleUploadProduct}
                />
              </div>
            </div>
          </label>
          <div>
            {data.productImage.length > 0 ? (
              <div className="flex items-center gap-2">
                {data.productImage.map((el, index) => (
                  <div className="relative group" key={index}>
                    <Zoom>
                      <img
                        src={el.url}
                        alt={el.publicId}
                        width={80}
                        height={80}
                        className="border border-customGold cursor-pointer"
                        onClick={() => {
                          setOpenFullScreenImage(true);
                          setFullScreenImage(el.url);
                        }}
                      />
                    </Zoom>
                    <div
                      className="absolute bottom-0 right-0 p-1 rounded-full hidden group-hover:block cursor-pointer"
                      onClick={() => handleDeleteProductImage(index)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-red-600 text-xs">
                *Please upload product image
              </p>
            )}
          </div>

          <label htmlFor="price" className="mt-3">
            Price :
          </label>
          <input
            type="number"
            id="price"
            placeholder="Enter price"
            value={data.price}
            name="price"
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <label htmlFor="sellingPrice" className="mt-3">
            Selling Price :
          </label>
          <input
            type="number"
            id="sellingPrice"
            placeholder="Enter selling price"
            value={data.sellingPrice}
            name="sellingPrice"
            onChange={handleOnChange}
            className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold"
            required
          />

          <label htmlFor="description" className="mt-3">
            Description :
          </label>
          <textarea
            id="description"
            className="bg-black mt-1 block w-full p-3 border placeholder-customGold border-customGold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-customGold focus:border-customGold resize-none h-28"
            placeholder="Enter product description"
            rows={3}
            onChange={handleOnChange}
            name="description"
            value={data.description}
            required
          ></textarea>

          <button className="px-3 py-2 mb-10 border border-customGold hover:bg-customGoldHover hover:text-black rounded-md">
            Update Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminEditProduct;
