import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SummaryAPI from "../common";
import { IPlaceOrder } from "../interface/pageInterface";

const OrderList: React.FC = () => {
  const [orders, setOrders] = useState<IPlaceOrder[]>([]);

  // Fetch Orders
  const fetchOrders = async () => {
    const fetchOrdersData = await fetch(SummaryAPI.getOrderDetails.URL, {
      method: SummaryAPI.getOrderDetails.method,
      credentials: "include",
    });
    const orderResponse = await fetchOrdersData.json();
    if (orderResponse.success) {
      setOrders(orderResponse.data);
    }
    if (orderResponse.error) {
      setOrders([]);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  if (orders.length === 0)
    return <div className="container mx-auto px-4 py-6">No Orders yet</div>;

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-4">Orders List</h1>

      {/* Responsive grid for order cards */}
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {orders.map((order) => (
          <div
            key={order._id}
            className="p-4 rounded-lg shadow-md border border-customGold"
          >
            <div className="flex flex-col justify-between h-full">
              <div>
                {/* Order Details */}
                <h2 className="text-xl font-semibold mb-2 border-b border-customGold pb-2">
                  Order ID: {order._id}
                </h2>
                <p className="break-words">User ID: {order.userId}</p>
                <p>Payment Mode: {order.paymentMode}</p>

                <p>
                  Payment Status:{" "}
                  <span
                    className={`font-semibold ${
                      order.paymentStatus === "Completed"
                        ? "text-green-600"
                        : order.paymentStatus === "Pending"
                        ? "text-yellow-600"
                        : "text-red-600"
                    }`}
                  >
                    {order.paymentStatus}
                  </span>
                </p>

                <p>
                  Delivery Status:{" "}
                  <span
                    className={`font-semibold ${
                      order.deliveryStatus === "Delivered"
                        ? "text-green-600"
                        : order.deliveryStatus === "Shipped"
                        ? "text-blue-600"
                        : "text-yellow-600"
                    }`}
                  >
                    {order.deliveryStatus}
                  </span>
                </p>
              </div>

              {/* Link to order details */}
              <Link
                to={`/order/${order._id}`}
                className="mt-4 text-blue-600 font-semibold hover:underline border-t border-customGold pt-2"
              >
                View Details
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderList;
